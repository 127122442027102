import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
// import Wrap from 'components/Wrap'
import HeadlineComponent from "components/Headline";
import GridComponent from "components/Grid";
import GridItemComponent from "components/GridItem";
import Image from "components/Image";

export const Grid = styled(GridComponent)({
  [media(0, "tabletFluid")]: {
    flexDirection: "column-reverse",
  },
});
export const RightCol = styled(GridItemComponent)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "800px",
  position: "relative",
  zIndex: 0,

  [media(0, "tabletFluid")]: {
    transform: "scale(0.6)",
    margin: "-180px 0 -180px",
  },
  [media("tabletFluid", "laptop")]: {
    transform: "scale(0.8)",
  },
});
export const GridItem = styled(GridItemComponent)({});
export const Headline = styled(HeadlineComponent)({});
export const Circle = styled("div")((props) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",

  "&:before": {
    content: '" "',
    position: "relative",
    // paddingTop: '100%',
    borderRadius: "100%",
    background: props.theme.colorTertiary,
    display: "block",
    width: 700,
    height: 700,
  },
}));

const phoneStyles = (props) => ({
  backgroundSize: "100%",
  position: "absolute",
  left: "0",
  top: "0",
});

export const Phone1 = styled("div")((props) => ({
  ...phoneStyles(props),
  transform: "translate(-100%,-40%) rotateZ(-10deg)",
}));

export const Phone2 = styled("div")((props) => ({
  ...phoneStyles(props),
  transform: "translate(-10%,-75%) rotateZ(5deg)",
}));

const phoneWrapStyles = (props) => ({
  position: "absolute",
  left: "50%",
  top: "50%",
});

export const Phone1Wrap = styled("div")((props) => ({
  ...phoneWrapStyles(props),
  zIndex: 2,
}));

export const Phone2Wrap = styled("div")((props) => ({
  ...phoneWrapStyles(props),
  zIndex: 1,
}));

const screenStyles = (props) => ({
  overflow: "hidden",
  borderRadius: 30,
  position: "absolute",
  height: "calc(100% - 26px)",
  width: "calc(100% - 20px)",
  background: "black",
});

export const Screen1 = styled("div")((props) => ({
  ...screenStyles(props),
  top: 13,
  left: 17,
  transform: "perspective(900px) rotateY(10deg)",
}));

export const Screen2 = styled("div")((props) => ({
  ...screenStyles(props),
  top: 13,
  left: 2,
  transform: "perspective(900px) rotateY(-10deg)",
}));

export const ScreenImageWrap = styled("div")((props) => ({
  // display: 'none',
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  zIndex: 0,
  opacity: props.active ? 1 : 0,
  transition: "opacity 500ms ease-out",
  transitionDelay: props.active
    ? `${500 + props.delay}ms`
    : `${0 + props.delay}ms`,
}));

export const ScreenImage = styled(Image)((props) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  zIndex: 0,
}));

export const PhoneImage = styled(Image)((props) => ({
  position: "reative",
  // top: 0,
  // left: 0,
  pointerEvents: "none",
  zIndex: 1,
}));

export const SectionStyled = styled("section")(
  (props) => ({
    position: "relative",
    padding: "120px 0 50px",
    overflow: "hidden",
    zIndex: 1,
    [media("tablet")]: {
      padding: "140px 0 80px",
    },
    background: props.theme.sectionTextBackground,
  }),
  (props) =>
    props.listStyleImage && {
      ul: {
        listStyle: "none",
        paddingLeft: parseFloat(props.listStyleImage.width) / 2,

        li: {
          position: "relative",
          marginBottom: 20,
          paddingLeft: parseFloat(props.listStyleImage.width) + 5,
          "&:before": {
            content: "' '",
            display: "block",
            position: "absolute",
            left: 0,
            top: 11,
            height: `${props.listStyleImage.height}px`,
            width: `${props.listStyleImage.width}px`,
            transform: "translate(-50%,-50%)",
            background: `url(${props.listStyleImage.url}) no-repeat center center`,
            backgroundSize: "contain",
          },
        },
      },
    }
);

export const TextStyled = styled("div")({}, (props) => ({
  margin: props.margin,
  maxWidth: "none",

  [media("tabletFluid")]: {
    marginLeft: props.layout === "left" ? "0" : "auto",
    marginRight: props.layout === "right" ? "0" : "auto",
  },

  [media("laptop")]: {
    maxWidth: props.maxWidth || "none",
  },

  // p: {
  // margin: 0,
  // },
}));
