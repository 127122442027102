import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Redactor from 'components/Redactor'
import SwitchTheme from 'components/SwitchTheme'
import Image from 'components/Image'
import Link from 'components/Link'

import { parseAsText } from 'utils/parser'

import {
  SectionStyled,
  Grid,
  GridItem,
  RightCol,
  Headline,
  TextStyled,
  Circle,
  Phone1,
  Phone1Wrap,
  Phone2,
  Phone2Wrap,
  Screen1,
  Screen2,
  PhoneImage,
  ScreenImageWrap,
  ScreenImage,
} from './styles'

export default function Text({ pageBuilder, className }) {
  const {
    appShowcaseHeadline,
    appShowcaseHeadlineTag,
    appShowcaseBulletImage,
    appShowcaseText,
    appShowcasePhone1Image,
    appShowcasePhone2Image,
    imagesLeftScreen,
    imagesRightScreen,
    appShowcaseLinks,
  } = pageBuilder

  const [screen1Active, setScreen1Active] = useState(0)
  const [screen2Active, setScreen2Active] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setScreen1Active(
        screen1Active < imagesLeftScreen.length - 1 ? screen1Active + 1 : 0
      )
      setScreen2Active(
        screen2Active < imagesRightScreen.length - 1 ? screen2Active + 1 : 0
      )
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  })

  const textContent = appShowcaseText?.content
  const listStyleImage = appShowcaseBulletImage?.[0]

  return (
    <SwitchTheme>
      <SectionStyled className={className} listStyleImage={listStyleImage}>
        <Grid>
          <GridItem desktop="40%" laptop="60%" style={{ zIndex: 1 }}>
            {appShowcaseHeadline && (
              <Fade bottom distance="10px" delay={200}>
                <Headline
                  element={appShowcaseHeadlineTag}
                  // lineHeight="1.1"
                  // headlinefontsize={69}
                >
                  {parseAsText(appShowcaseHeadline)}
                </Headline>
              </Fade>
            )}

            {appShowcaseLinks && appShowcaseLinks.length > 0 && (
              <Fade bottom distance="10px" delay={400}>
                <div style={{ display: 'flex', margin: '50px 0' }}>
                  {appShowcaseLinks.map(({ linkUrl, linkImage }, index) => (
                    <div style={{ marginRight: 20 }} key={'link-' + index}>
                      <Link to={linkUrl.url}>
                        <Image
                          style={{
                            width: `${parseInt(linkImage[0].width) + 1}px`,
                            height: `${parseInt(linkImage[0].height)}px`,
                          }}
                          fluid={linkImage[0]}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </Fade>
            )}

            {textContent && (
              <Fade bottom distance="10px" delay={600}>
                <TextStyled>
                  <Redactor content={textContent} parseType="content" />
                </TextStyled>
              </Fade>
            )}
          </GridItem>
          <RightCol desktop="60%" laptop="40%">
            <Fade bottom distance="10px" delay={800}>
              <Circle />
              <div style={{ position: 'relative' }}>
                {appShowcasePhone1Image && (
                  <Phone1Wrap>
                    <Fade bottom distance="10px" delay={1200}>
                      <Phone1 image={appShowcasePhone1Image}>
                        <PhoneImage
                          style={{
                            width: `${appShowcasePhone1Image[0].width / 2}px`,
                            height: `${appShowcasePhone1Image[0].height / 2}px`,
                          }}
                          fluid={appShowcasePhone1Image[0]}
                          aspectRatio={
                            appShowcasePhone1Image[0].width /
                            appShowcasePhone1Image[0].height
                          }
                        />
                        <Screen1>
                          {imagesLeftScreen.map((image, index) => (
                            <ScreenImageWrap
                              key={`image-${index}`}
                              active={index === screen1Active}
                              delay={0}>
                              <ScreenImage
                                fluid={image}
                                aspectRatio={image.width / image.height}
                              />
                            </ScreenImageWrap>
                          ))}
                        </Screen1>
                      </Phone1>
                    </Fade>
                  </Phone1Wrap>
                )}
                {appShowcasePhone2Image && (
                  <Phone2Wrap>
                    <Fade bottom distance="10px" delay={900}>
                      <Phone2>
                        <PhoneImage
                          style={{
                            width: `${appShowcasePhone2Image[0].width / 2}px`,
                            height: `${appShowcasePhone2Image[0].height / 2}px`,
                          }}
                          fluid={appShowcasePhone2Image[0]}
                          aspectRatio={
                            appShowcasePhone2Image[0].width /
                            appShowcasePhone2Image[0].height
                          }
                        />
                        <Screen2 className="Screen2">
                          {imagesRightScreen.map((image, index) => (
                            <ScreenImageWrap
                              key={`image-${index}`}
                              active={index === screen2Active}
                              delay={200}>
                              <ScreenImage
                                fluid={image}
                                aspectRatio={image.width / image.height}
                              />
                            </ScreenImageWrap>
                          ))}
                        </Screen2>
                      </Phone2>
                    </Fade>
                  </Phone2Wrap>
                )}
              </div>
            </Fade>
          </RightCol>
        </Grid>
      </SectionStyled>
    </SwitchTheme>
  )
}

export const query = graphql`
  fragment PageBuilderAppShowcaseQuery on Craft_PageBuilderAppShowcase {
    id
    appShowcaseHeadline
    appShowcaseHeadlineTag
    appShowcaseText {
      content
    }
    appShowcaseBulletImage {
      ...CraftImageFluid
    }
    imagesLeftScreen {
      ...CraftImageFluid
    }
    imagesRightScreen {
      ...CraftImageFluid
    }
    appShowcasePhone1Image {
      ...CraftImageFluid
    }
    appShowcasePhone2Image {
      ...CraftImageFluid
    }
    appShowcaseLinks {
      id
      linkUrl {
        url
      }
      linkImage {
        ...CraftImageFluid
      }
    }
  }
`
